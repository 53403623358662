import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

export const CartContext = createContext();

export const Provider = ({ children }) => {
  const [cartInfo, setCartInfo] = useState({});
  const [currency, setCurrency] = useState('cad');

  useEffect(() => {
    const existingCartInfo = JSON.parse(localStorage.getItem('cartInfo'));
    const existingCurrency = JSON.parse(localStorage.getItem('currency'));

    if (existingCartInfo) {
      if (dayjs().isBefore(existingCartInfo.expires)) {
        setCartInfo(existingCartInfo.cartInfo);
      } else {
        localStorage.removeItem('cartInfo');
      }
    }

    if (existingCurrency) {
      setCurrency(existingCurrency);
    }
  }, []);

  return (
    <CartContext.Provider value={{ cartInfo, setCartInfo, currency, setCurrency }}>{children}</CartContext.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

const CartProvider = ({ element }) => <Provider>{element}</Provider>;

CartProvider.propTypes = {
  element: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default CartProvider;
