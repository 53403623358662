// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cart-index-jsx": () => import("./../../../src/pages/cart/index.jsx" /* webpackChunkName: "component---src-pages-cart-index-jsx" */),
  "component---src-pages-guides-index-jsx": () => import("./../../../src/pages/guides/index.jsx" /* webpackChunkName: "component---src-pages-guides-index-jsx" */),
  "component---src-pages-products-index-jsx": () => import("./../../../src/pages/products/index.jsx" /* webpackChunkName: "component---src-pages-products-index-jsx" */),
  "component---src-templates-blog-page-jsx": () => import("./../../../src/templates/BlogPage.jsx" /* webpackChunkName: "component---src-templates-blog-page-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/BlogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-guide-page-jsx": () => import("./../../../src/templates/GuidePage.jsx" /* webpackChunkName: "component---src-templates-guide-page-jsx" */),
  "component---src-templates-home-page-jsx": () => import("./../../../src/templates/HomePage.jsx" /* webpackChunkName: "component---src-templates-home-page-jsx" */),
  "component---src-templates-legal-page-jsx": () => import("./../../../src/templates/LegalPage.jsx" /* webpackChunkName: "component---src-templates-legal-page-jsx" */),
  "component---src-templates-order-confirmation-page-jsx": () => import("./../../../src/templates/OrderConfirmationPage.jsx" /* webpackChunkName: "component---src-templates-order-confirmation-page-jsx" */),
  "component---src-templates-product-page-jsx": () => import("./../../../src/templates/ProductPage.jsx" /* webpackChunkName: "component---src-templates-product-page-jsx" */)
}

